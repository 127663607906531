import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { GET_BY_PATH_QUERY } from '../graphql/queries'
import { StudioGroup, StudioGroupTemplate } from '../types'
import { GetPathQuery, GetPathQueryVariables } from '../__graphql__/queries'

export type FetchPathService = (
  term: string,
) => Promise<StudioGroup | StudioGroupTemplate>

export const useFetchPath = (): FetchPathService => {
  const client = useApolloClient()

  return useCallback(
    async (term: string) => {
      const { data } = await client.query<GetPathQuery, GetPathQueryVariables>({
        errorPolicy: 'all',
        query: GET_BY_PATH_QUERY,
        fetchPolicy: 'no-cache',
        variables: { term },
      })

      if (!data.searchByPath) {
        throw new Error('Not Data Returned From API')
      }

      if (data.searchByPath.__typename === 'StudioError') {
        throw new Error(data.searchByPath.__typename)
      }

      if (data.searchByPath.__typename === 'StudioNotFound') {
        throw new Error(data.searchByPath.__typename)
      }

      return data.searchByPath
    },
    [client],
  )
}
