import {
  StudioColor,
  StudioImageElement,
  StudioRectangleElement,
  StudioTemplate,
  StudioTextElement,
} from '../types'
import {
  StudioAssetInput,
  StudioAssetStatus,
  StudioColorInput,
  StudioFontInput,
  StudioImageElementInput,
  StudioPlaceholderTextElementInput,
  StudioPlainTextElementInput,
  StudioPlainTextPartInput,
  StudioRectangleElementInput,
  StudioStaticTextElementInput,
  StudioStaticTextPartInput,
  StudioStyledTextElementInput,
  StudioTextIntentInput,
  StudioTextIntent,
  StudioUpdateTemplateInput,
  Maybe,
} from '../__graphql__/types'
import { UNSET_ASSET_KEY } from '../constants'
import {
  DEFAULT_AVAILABLE_FONT_COLORS,
  DEFAULT_AVAILABLE_FONT_SIZES,
  DEFAULT_AVAILABLE_FONTS,
} from '../fontDefaults'

const getCommonElementProperties = (
  element: StudioImageElement | StudioTextElement | StudioRectangleElement,
  index: number,
) => {
  return {
    index,
    name: element.name,
    x: element.x,
    y: element.y,
    width: element.width,
    height: element.height,
    rotation: element.rotation,
    visible: element.visible,
  }
}

const mapAsset = async (asset: {
  key: string
  contentType: string
  status: StudioAssetStatus
}): Promise<StudioAssetInput> => ({
  key: asset.key && asset.key !== UNSET_ASSET_KEY ? asset.key : null,
  contentType: asset.contentType,
  status: asset.status,
})

const mapColor = (color: StudioColor) => {
  return {
    name: color.name,
    hex: color.hex,
  }
}

const mapFont = (font: StudioFontInput) => ({
  id: font.id,
})

const mapImageElement = async (
  element: StudioImageElement,
  index: number,
): Promise<StudioImageElementInput> => {
  const mask = element.mask ? await mapAsset(element.mask) : null
  const image = await mapAsset(element.image)
  return {
    ...getCommonElementProperties(element, index),
    editable: element.editable,
    mask,
    image,
    required: element.required,
  }
}

const getCommonTextProperties = (
  element: StudioTextElement,
  index: number,
): Omit<StudioStaticTextElementInput, 'text'> => {
  const { __typename: colorTypeName, ...color } = element.color
  return {
    ...getCommonElementProperties(element, index),
    horizontalAlignment: element.horizontalAlignment,
    verticalAlignment: element.verticalAlignment,
    lineSpacing: element.lineSpacing,
    font: mapFont(element.font),
    fontSize: element.fontSize,
    color: {
      ...color,
    },
    shadow: element.shadow
      ? {
          color: {
            name: element.shadow.color.name,
            hex: element.shadow.color.hex,
          },
          offsetX: element.shadow.offsetX,
          offsetY: element.shadow.offsetY,
        }
      : undefined,
  }
}

const setAvailableFonts = (
  styledTextElements: StudioStyledTextElementInput[],
): StudioFontInput[] => {
  const usedFonts = styledTextElements.map(te => ({ id: te.font.id }))
  const mergedAvailableFonts = [...DEFAULT_AVAILABLE_FONTS, ...usedFonts]

  const uniqueIds = new Set()
  return mergedAvailableFonts.filter(font => {
    const idIsUnique = !uniqueIds.has(font.id)
    return idIsUnique && uniqueIds.add(font.id)
  })
}

const setAvailableFontSizes = (
  styledTextElements: StudioStyledTextElementInput[],
): number[] => {
  const usedFontSizes = styledTextElements.map(te => te.fontSize)
  return Array.from(
    new Set([...DEFAULT_AVAILABLE_FONT_SIZES, ...usedFontSizes]),
  ).sort((a, b) => a - b)
}

const setColors = (
  styledTextElements: StudioStyledTextElementInput[],
): StudioColorInput[] => {
  const usedColors = styledTextElements.map(te => ({
    name: te.color.name,
    hex: te.color.hex,
  }))
  const mergedAvailableColors = [
    ...DEFAULT_AVAILABLE_FONT_COLORS,
    ...usedColors,
  ]

  const uniqueHexes = new Set()
  return mergedAvailableColors.filter(color => {
    const hexIsUnique = !uniqueHexes.has(color.hex)
    return hexIsUnique && uniqueHexes.add(color.hex)
  })
}

const setBleedValues = (
  xBleed: number | null | undefined,
  yBleed: number | null | undefined,
) => {
  if (xBleed && yBleed) return { xBleed, yBleed }
  return {}
}

const mapTextIntents = (
  textIntents: Maybe<StudioTextIntent[]> | undefined,
): StudioTextIntentInput[] => {
  if (!textIntents) return []
  return textIntents.map(intent => ({
    intent: intent.intent,
    format: intent.format,
  }))
}

export const mapTemplate = async (
  template: StudioTemplate,
): Promise<StudioUpdateTemplateInput> => {
  let imageElements: StudioImageElementInput[] = []
  let staticTextElements: StudioStaticTextElementInput[] = []
  let plainTextElements: StudioPlainTextElementInput[] = []
  let styledTextElements: StudioStyledTextElementInput[] = []
  let placeholderTextElements: StudioPlaceholderTextElementInput[] = []
  let rectangleElements: StudioRectangleElementInput[] = []

  for (const [index, element] of template.elements.entries()) {
    if (element.__typename === 'StudioImageElement') {
      imageElements.push(await mapImageElement(element, index))
    }

    if (element.__typename === 'StudioTextElement') {
      if (element.text.__typename === 'StudioStaticTextPart') {
        staticTextElements.push({
          ...getCommonTextProperties(element, index),
          text: element.text.text,
          textIntent: mapTextIntents(element.text.textIntent),
        })
      }

      if (element.text.__typename === 'StudioPlainTextPart') {
        const textElement = {
          ...getCommonTextProperties(element, index),
          text: element.text.text,
          allowBlank: element.text.allowBlank,
          allowDefault: element.text.allowDefault,
          maxCharacters: element.text.maxCharacters,
          textTransform: element.text.textTransform,
          customNo: element.text.customNo,
          textIntent: mapTextIntents(element.text.textIntent),
        }
        plainTextElements.push(textElement)
      }

      if (element.text.__typename === 'StudioStyledTextPart') {
        styledTextElements.push({
          ...getCommonTextProperties(element, index),
          text: element.text.text,
          allowBlank: element.text.allowBlank,
          allowDefault: element.text.allowDefault,
          textIntent: mapTextIntents(element.text.textIntent),
        })
      }

      if (element.text.__typename === 'StudioPlaceholderTextPart') {
        let staticTextParts: StudioStaticTextPartInput[] = []
        let plainTextParts: StudioPlainTextPartInput[] = []

        element.text.textParts.forEach((textPart, partIndex) => {
          if (textPart.__typename === 'StudioStaticTextPart') {
            staticTextParts.push({
              index: partIndex,
              text: textPart.text,
              textIntent: mapTextIntents(textPart.textIntent),
            })
            return
          }

          if (textPart.__typename === 'StudioPlainTextPart') {
            const mappedTextPart: StudioPlainTextPartInput = {
              index: partIndex,
              text: textPart.text,
              allowBlank: textPart.allowBlank,
              allowDefault: textPart.allowDefault,
              maxCharacters: textPart.maxCharacters,
              textTransform: textPart.textTransform,
              customNo: partIndex,
              textIntent: mapTextIntents(textPart.textIntent),
            }
            plainTextParts.push(mappedTextPart)
            return
          }
        })

        placeholderTextElements.push({
          ...getCommonTextProperties(element, index),
          plainTextParts,
          staticTextParts,
        })
      }
    }

    if (element.__typename === 'StudioRectangleElement') {
      rectangleElements.push({
        ...getCommonElementProperties(element, index),
        outline: {
          width: element.outline.width,
          color: mapColor(element.outline.color),
        },
        fill: {
          color: mapColor(element.fill.color),
        },
      })
    }
  }

  const bleedValues = setBleedValues(template.xBleed, template.yBleed)

  const mappedTemplate: StudioUpdateTemplateInput = {
    id: template.id,
    version: template.version,
    displayName: template.displayName,
    orientation: template.orientation,
    width: template.width,
    height: template.height,
    availableFonts: setAvailableFonts(styledTextElements),
    availableFontSizes: setAvailableFontSizes(styledTextElements),
    colors: setColors(styledTextElements),
    renderMethod: template.renderMethod,
    staticTextElements,
    plainTextElements,
    styledTextElements,
    placeholderTextElements,
    rectangleElements,
    imageElements,
    ...bleedValues,
  }
  return mappedTemplate
}
