import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import polly from 'polly-js'
import { UPDATE_TEMPLATE } from '../graphql/mutations'
import { StudioTemplate, StudioUser } from '../types'
import { mapTemplate } from '../utils/mapTemplateForApi'
import { UPDATE_TEMPLATE_ATTEMPTS } from '../constants'
import {
  UpdateTemplateMutation,
  UpdateTemplateMutationVariables,
} from '../__graphql__/mutations'
import {
  StudioUpdateTemplateResult,
  StudioUpdatedTemplate,
} from '../__graphql__/types'

export type UpdateTemplateService = (
  template: StudioTemplate,
  user: StudioUser,
  returnResponseForErrors: string[],
) => Promise<StudioUpdateTemplateResult>

export const useUpdateTemplate = (): UpdateTemplateService => {
  const client = useApolloClient()

  return useCallback(
    async (template, user, returnResponseForErrors) => {
      return polly()
        .handle(err => err.message !== 'StudioUpdatedTemplateError')
        .waitAndRetry(UPDATE_TEMPLATE_ATTEMPTS)
        .executeForPromise(async () => {
          const { data } = await client.mutate<
            UpdateTemplateMutation,
            UpdateTemplateMutationVariables
          >({
            errorPolicy: 'all',
            mutation: UPDATE_TEMPLATE,
            variables: {
              input: {
                template: await mapTemplate(template),
                createdBy: user.email,
              },
            },
          })

          if (data) {
            const hasIgnoredError = returnResponseForErrors.includes(
              data.updateTemplate.__typename,
            )

            if (hasIgnoredError) {
              return data.updateTemplate as StudioUpdatedTemplate
            }

            if (data.updateTemplate.__typename === 'StudioUpdatedTemplate') {
              return data.updateTemplate
            }

            if (data.updateTemplate.__typename === 'StudioDuplicateNameError') {
              throw new Error(data.updateTemplate.__typename)
            }

            if (
              data.updateTemplate.__typename ===
              'StudioTemplateValidationErrorResult'
            ) {
              throw new Error(data.updateTemplate.__typename)
            }

            if (
              data.updateTemplate.__typename === 'StudioUpdatedTemplateError'
            ) {
              throw new Error(data.updateTemplate.__typename)
            }
          }

          throw new Error('StudioUpdatedTemplateUnknownError')
        })
    },
    [client],
  )
}
