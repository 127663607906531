import * as React from 'react'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import {
  createAllMoveObservable,
  createDndObservable,
  createKeyboardMoveObservable,
  createResizeObservable,
  createRotateObservable,
} from './observables'
import { Position } from './types'
import {
  calculateResizeObservableConfigs,
  calculateRotateObservableConfigs,
  isFunction,
  objectsAreEqual,
  randomString,
  RESIZE_HANDLE_LOCATIONS,
  RESIZE_HANDLE_LOCATIONS_PRESERVE_ASPECT,
} from './utils/misc'
import { createInteractionStartObservable } from './observables/interactionStart'
import { createInteractionEndObservable } from './observables/interactionEnd'

type PositionableState = Position

export interface PositionableProps {
  /**
   * Convert template space to pixel space
   */
  unitsPerPixel: number
  /**
   * Lock aspect ratio
   */
  aspectRatioLock?: boolean
  /**
   * Should all functionality be disabled? This property takes
   * precedence over `movable`, `resizable`, and `rotatable`.
   */
  disabled?: boolean

  /**
   * By default, if `movable` is `true`, both mouse and keyboard movement
   * are enabled. This prop allows keyboard-based movement to be disabled.
   */
  disableKeyboardMovement?: boolean

  /**
   * Members of the same group will respond
   * to each other's drag and drop events.
   */
  group?: string

  /** Should moving be enabled? */
  movable?: boolean

  /** Callback to notify when Positioning has changed */
  onUpdate?: (sizing: Position) => void

  /** Callback to notify when Positioning has moved */
  onMove?: (sizing: Position) => void

  /** Callback to notify when Positioning has moved */
  onResize?: (sizing: Position) => void

  /** Callback to notify when Rotation has moved */
  onRotate?: (sizing: Position) => void

  /** Callback to notify interaction has started */
  onInteractionStart?: () => void

  /** Callback to notify interaction has started */
  onInteractionEnd?: () => void

  /** Current Positioning (left, top, width, height, rotation) */
  position: Position

  /** Render Prop alternative to using `children` */
  render?: RenderCallback

  /** Should resizing be enabled? */
  resizable?: boolean

  /** Should rotation be enabled? */
  rotatable?: boolean

  /** Snap drag and resize to pixels of this interval. */
  snapTo?: number

  /**
   * Snap horizontal drag and resize to pixels of this interval
   * (overwrites snapTo for horizontal values). Setting this value
   * to `0` disables horizontal changes.
   */
  snapXTo?: number

  /**
   * Snap vertical drag and resize to pixels of this interval
   * (overwrites snapTo for vertical values). Setting this value
   * to `0` disables vertical changes.
   */
  snapYTo?: number
}

type RenderCallback = (args: RenderCallbackArgs) => JSX.Element

export interface RenderCallbackArgs {
  renderedPosition: Position
  refHandlers: Positionable['refHandlers']
}

export class Positionable extends React.Component<
  PositionableProps,
  PositionableState
> {
  public static defaultProps = {
    resizable: [],
  }

  public readonly state: PositionableState

  private refHandlers = {
    container: React.createRef<any>(),

    dnd: React.createRef<any>(),

    neRotate: React.createRef<any>(),
    seRotate: React.createRef<any>(),
    swRotate: React.createRef<any>(),
    nwRotate: React.createRef<any>(),

    nResize: React.createRef<any>(),
    neResize: React.createRef<any>(),
    eResize: React.createRef<any>(),
    seResize: React.createRef<any>(),
    sResize: React.createRef<any>(),
    swResize: React.createRef<any>(),
    wResize: React.createRef<any>(),
    nwResize: React.createRef<any>(),
  }

  private destroy$ = new Subject<void>()
  private _interacting: boolean = false

  constructor(props: PositionableProps) {
    super(props)
    this.state = { ...props.position }
  }

  public componentDidMount() {
    this.buildSubscriptions()
  }

  public componentWillUnmount() {
    this.destroy$.next()
  }

  /**
   * Update subscriptions and internal Position if the props change.
   */
  public componentDidUpdate(prevProps: PositionableProps) {
    if (objectsAreEqual(this.props, prevProps)) {
      return
    }

    const { position, ...rest } = this.props
    const { position: prevPosition, ...prevRest } = prevProps

    if (!objectsAreEqual(position, prevPosition)) {
      this.setState(this.props.position)
    }

    if (!objectsAreEqual(rest, prevRest)) {
      this.buildSubscriptions()
    }
  }

  public render() {
    const { render } = this.props

    const passedProps: RenderCallbackArgs = {
      renderedPosition: this.state,
      refHandlers: this.refHandlers,
    }

    if (isFunction(render)) {
      return render(passedProps)
    }

    throw new Error(
      'Positionable must receive `render` or `children` as render callback',
    )
  }

  /**
   * Call `onUpdate()` prop if position has changed.
   */
  private handleUpdate = () => {
    if (
      !this.props.onUpdate ||
      objectsAreEqual(this.state, this.props.position)
    ) {
      return
    }
    this.props.onUpdate(this.state)
  }

  private fireInteractionStartIfNeeded() {
    if (this.props.onInteractionStart && !this._interacting) {
      this.props.onInteractionStart()
      this._interacting = true
    }
  }

  /**
   * Call `onMove()` prop if position has moved.
   */
  private handleMove = (position: any) => {
    this.fireInteractionStartIfNeeded()
    if (!this.props.onMove) {
      return
    }
    this.props.onMove(position)
  }

  private handleResize = (position: any) => {
    this.fireInteractionStartIfNeeded()
    if (!this.props.onResize) {
      return
    }
    this.props.onResize(position)
  }

  private handleRotate = (position: any) => {
    this.fireInteractionStartIfNeeded()
    if (!this.props.onRotate) {
      return
    }
    this.props.onRotate(position)
  }

  /**
   * Handle subscribing to and unsubscribing from Observables.
   */
  private buildSubscriptions() {
    const {
      unitsPerPixel,
      disabled,
      disableKeyboardMovement,
      movable,
      resizable,
      rotatable,
      snapTo,
      snapXTo,
      snapYTo,
      aspectRatioLock,
      onInteractionStart,
      onInteractionEnd,
    } = this.props
    const { left, width } = this.state
    const group = this.props.group || randomString()

    this.destroy$.next()

    // We need, at the bare minimum, a `container` ref.
    if (!this.refHandlers.container.current) {
      return
    }

    if (onInteractionStart) {
      createInteractionStartObservable({
        element:
          this.refHandlers.dnd.current || this.refHandlers.container.current,
      })
        .pipe(takeUntil(this.destroy$))
        .subscribe(onInteractionStart)
    }

    if (onInteractionEnd) {
      createInteractionEndObservable({
        element:
          this.refHandlers.dnd.current || this.refHandlers.container.current,
      })
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this._interacting = false
          onInteractionEnd()
        })
    }

    // If `disabled`, only the click observable will be created.
    if (disabled) {
      return
    }

    if (movable) {
      createDndObservable({
        element: this.refHandlers.container.current,
        group,
        handle:
          this.refHandlers.dnd.current || this.refHandlers.container.current,
        snapTo,
        snapXTo,
        snapYTo,
      })
        .pipe(takeUntil(this.destroy$))
        .subscribe()

      createAllMoveObservable({
        element: this.refHandlers.container.current,
        group,
        onMove: position => this.handleMove(position),
        onComplete: this.handleUpdate,
        shouldConvertToPercent: left.includes('%'),
      })
        .pipe(takeUntil(this.destroy$))
        .subscribe(newCoords => this.setState(newCoords))

      if (!disableKeyboardMovement) {
        createKeyboardMoveObservable({
          element: this.refHandlers.container.current,
          unitsPerPixel,
          onComplete: this.handleUpdate,
          shouldConvertToPercent: left.includes('%'),
        })
          .pipe(takeUntil(this.destroy$))
          .subscribe(newCoords => this.setState(newCoords))
      }
    }

    if (resizable) {
      const resizeObservableConfigs = calculateResizeObservableConfigs(
        aspectRatioLock
          ? RESIZE_HANDLE_LOCATIONS_PRESERVE_ASPECT
          : RESIZE_HANDLE_LOCATIONS,
      )

      resizeObservableConfigs.forEach(config => {
        // @ts-ignore
        const handle = this.refHandlers[config.refHandlerName].current

        if (!handle) {
          return
        }

        createResizeObservable({
          element: this.refHandlers.container.current!,
          handle,
          onMove: this.handleUpdate,
          onResize: position => this.handleResize(position),
          onComplete: this.handleUpdate,
          top: config.top,
          right: config.right,
          bottom: config.bottom,
          left: config.left,
          shouldConvertToPercent: width.includes('%'),
          snapTo,
          snapXTo,
          snapYTo,
          aspectRatioLock,
        })
          .pipe(takeUntil(this.destroy$))
          .subscribe(newPosition => this.setState(newPosition))
      })
    }

    if (rotatable) {
      const rotateObservableConfigs = calculateRotateObservableConfigs()

      rotateObservableConfigs.forEach(config => {
        // @ts-ignore
        const handle = this.refHandlers[config.refHandlerName].current

        if (!handle) {
          return
        }

        createRotateObservable({
          element: this.refHandlers.container.current!,
          handle,
          onComplete: this.handleUpdate,
          onRotate: position => this.handleRotate(position),
        })
          .pipe(takeUntil(this.destroy$))
          .subscribe(newRotation => this.setState(newRotation))
      })
    }
  }
}

export default Positionable
