import polly from 'polly-js'
import { StudioNode } from '../types'
import { ApolloClient, useApolloClient } from '@apollo/client'
import { RENAME_TEMPLATE } from '../graphql/mutations'
import { fetchTemplate } from './FetchTemplate'
import {
  RenameTemplateMutation,
  RenameTemplateMutationVariables,
} from '../__graphql__/mutations'
import { StudioRenameTemplateResult } from '../__graphql__/types'

export type RenameNodeService = (
  node: StudioNode,
  name: string,
) => Promise<StudioRenameTemplateResult | boolean>

const shouldNotRetryErrors = [
  'UnableToFetchTemplate',
  'StudioDuplicateNameError',
  'StudioInvalidNameError',
  'StudioRenameTemplateError',
  'StudioVersionMismatchError',
  'StudioDuplicatePathError',
]

const shouldRetry = (err: Error) => {
  return !shouldNotRetryErrors.includes(err.message)
}

const renameTemplate = async (
  client: ApolloClient<object>,
  node: StudioNode,
  name: string,
) => {
  return polly()
    .handle(shouldRetry)
    .waitAndRetry(5)
    .executeForPromise(async () => {
      let template = undefined

      try {
        template = await fetchTemplate(client, node.id)
      } catch (caught) {
        throw new Error('UnableToFetchTemplate')
      }

      const { data } = await client.mutate<
        RenameTemplateMutation,
        RenameTemplateMutationVariables
      >({
        errorPolicy: 'all',
        mutation: RENAME_TEMPLATE,
        variables: {
          input: {
            template: {
              id: node.id,
              name,
              version: template.version,
            },
          },
        },
      })

      if (data?.renameTemplate.__typename === 'StudioRenamedTemplate') {
        return data.renameTemplate
      }

      throw new Error(data?.renameTemplate.__typename)
    })
}

const renameGroup = async (
  _client: ApolloClient<object>,
  _node: StudioNode,
  _name: string,
) => {
  return polly()
    .handle(shouldRetry)
    .waitAndRetry(5)
    .executeForPromise(async () => {
      return true
    })
}

export const useRenameNode = (): RenameNodeService => {
  const client = useApolloClient()

  return async (node: StudioNode, name: string) => {
    if (node.__typename === 'StudioGroup') {
      return renameGroup(client, node, name)
    }

    if (node.__typename === 'StudioGroupTemplate') {
      return renameTemplate(client, node, name)
    }

    return false
  }
}
