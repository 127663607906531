import { FC } from 'react'
import { InteractiveFrame } from '../InteractiveFrame'
import { useSceneProvider } from '../../contexts/SceneProvider'

type InteractiveImageProps = {
  id: string
  unitsPerPixel: number
  x: number
  y: number
  width: number
  height: number
  rotation: number
}

export const InteractiveImage: FC<InteractiveImageProps> = ({
  id,
  x,
  y,
  width,
  height,
  rotation,
}) => {
  const scene = useSceneProvider()
  const selectedElementId = scene.state.selectedElement?.id
  const isSelected = id === selectedElementId

  return (
    <>
      <InteractiveFrame
        key={id}
        ariaLabel="Edit Image"
        selected={isSelected}
        onClick={() => {
          scene.actions.selectElement(id)
        }}
        frame={{ x, y, width, height, rotation }}
      />
    </>
  )
}
