import { ApolloClient, useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { GET_TEMPLATE_QUERY } from '../graphql/queries'
import {
  GetTemplateQuery,
  GetTemplateQueryVariables,
} from '../__graphql__/queries'
import { StudioTemplate, StudioTemplateResult } from '../__graphql__/types'

export type FetchTemplateService = (id: string) => Promise<StudioTemplateResult>

export const useFetchTemplate = (): FetchTemplateService => {
  const client = useApolloClient()

  return useCallback(async (id: string) => fetchTemplate(client, id), [client])
}

export const fetchTemplate = async (
  client: ApolloClient<object>,
  id: string,
) => {
  const { data, error } = await client.query<
    GetTemplateQuery,
    GetTemplateQueryVariables
  >({
    errorPolicy: 'all',
    query: GET_TEMPLATE_QUERY,
    fetchPolicy: 'no-cache',
    variables: { id },
  })

  if (error) {
    throw new Error(data.studioTemplateById.__typename)
  }

  if (data.studioTemplateById.__typename === 'StudioTemplateError') {
    throw new Error(data.studioTemplateById.__typename)
  }

  return data.studioTemplateById as StudioTemplate
}
