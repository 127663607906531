import {
  Checkbox,
  FormControlLabel,
  RadioButton,
} from '@moonpig/launchpad-forms'
import React, { FC, useEffect, useMemo, useState } from 'react'
import {
  StudioElement,
  StudioPlainTextPart,
  StudioStaticTextPart,
} from '../../../types'
import { Box } from '@moonpig/launchpad-components'
import { MaxCharactersOption } from './Options/MaxCharactersOption'
import { StudioTextTransform } from '../../../__graphql__/types'
import { useSceneProvider } from '../../../contexts/SceneProvider'
type PlaceholderParts = StudioStaticTextPart | StudioPlainTextPart

type PlaceholderOptionsProps = {
  selectedTextPartIndex: number | null
  textParts: PlaceholderParts[]
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

type PlaceholderRadioGroupProps = {
  textParts: PlaceholderParts[]
  currentIndex: number
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

type PlaceholderPlainTextOptionsProps = {
  textParts: PlaceholderParts[]
  textPart: StudioPlainTextPart
  currentIndex: number
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

const PlaceholderPlainTextOptions: FC<PlaceholderPlainTextOptionsProps> = ({
  textParts,
  textPart,
  currentIndex,
  onUpdateElement,
}) => {
  const updateMaxCharacters = (enabled: boolean) => {
    let updatedTextParts = textParts.map((part, index) => {
      if (index === currentIndex) {
        const maxCharacters = enabled ? textPart.text.length : null
        return {
          ...textPart,
          maxCharacters: maxCharacters,
        }
      }
      return part
    })
    onUpdateElement({
      text: {
        __typename: 'StudioPlaceholderTextPart',
        textParts: updatedTextParts,
      },
    })
  }

  return (
    <>
      <FormControlLabel
        name={`edit-required-checkbox-${currentIndex}`}
        label="Edit required"
        id={`edit-required-checkbox-${currentIndex}`}
        data-testid={`edit-required-checkbox-${currentIndex}`}
      >
        <Checkbox
          onChange={() => {
            let updatedTextParts = textParts.map((part, index) => {
              if (index === currentIndex) {
                return {
                  ...textPart,
                  allowDefault: !textPart.allowDefault,
                }
              }
              return part
            })
            onUpdateElement({
              text: {
                __typename: 'StudioPlaceholderTextPart',
                textParts: updatedTextParts,
              },
            })
          }}
          checked={!textPart.allowDefault}
          value=""
        />
      </FormControlLabel>
      <FormControlLabel
        name={`allow-blank-placeholder-checkbox-${currentIndex}`}
        label="Allow blank"
        id={`allow-blank-placeholder-checkbox-${currentIndex}`}
        data-testid={`allow-blank-placeholder-checkbox-${currentIndex}`}
      >
        <Checkbox
          onChange={() => {
            let updatedTextParts = textParts.map((part, index) => {
              if (index === currentIndex) {
                return {
                  ...textPart,
                  allowBlank: !textPart.allowBlank,
                }
              }
              return part
            })
            onUpdateElement({
              text: {
                __typename: 'StudioPlaceholderTextPart',
                textParts: updatedTextParts,
              },
            })
          }}
          checked={textPart.allowBlank}
          value=""
        />
      </FormControlLabel>

      <FormControlLabel
        name={`capitalise-placeholder-checkbox-${currentIndex}`}
        label="Capitalise"
        id={`capitalise-placeholder-checkbox-${currentIndex}`}
        data-testid={`capitalise-placeholder-checkbox-${currentIndex}`}
      >
        <Checkbox
          onChange={() => {
            let updatedTextParts = textParts.map((part, index) => {
              if (index === currentIndex) {
                return {
                  ...textPart,
                  textTransform:
                    textPart.textTransform === StudioTextTransform.UPPERCASE
                      ? StudioTextTransform.NONE
                      : StudioTextTransform.UPPERCASE,
                }
              }
              return part
            })
            onUpdateElement({
              text: {
                __typename: 'StudioPlaceholderTextPart',
                textParts: updatedTextParts,
              },
            })
          }}
          checked={textPart.textTransform === 'UPPERCASE'}
          value=""
        />
      </FormControlLabel>
      <MaxCharactersOption
        index={currentIndex}
        checked={!!textPart.maxCharacters && textPart.maxCharacters > 0}
        value={textPart.maxCharacters ?? 0}
        onUpdateCheckedState={value => updateMaxCharacters(value)}
        onUpdateValue={value => {
          let updatedTextParts = textParts.map((part, index) => {
            if (index === currentIndex) {
              return {
                ...textPart,
                maxCharacters: value,
              }
            }
            return part
          })
          onUpdateElement({
            text: {
              __typename: 'StudioPlaceholderTextPart',
              textParts: updatedTextParts,
            },
          })
        }}
      />
    </>
  )
}

const PlaceholderRadioGroup: FC<PlaceholderRadioGroupProps> = ({
  textParts,
  currentIndex,
  onUpdateElement,
}) => {
  const scene = useSceneProvider()
  const selectedElementId = scene.state.selectedElement?.id

  const textPart: StudioStaticTextPart | StudioPlainTextPart =
    textParts[currentIndex]

  return (
    <>
      <FormControlLabel
        label="Editable"
        id={`editable-placeholder-${currentIndex}`}
        data-testid={`editable-placeholder-${currentIndex}`}
      >
        <Checkbox
          checked={textPart.__typename === 'StudioPlainTextPart'}
          onChange={e => {
            let updatedTextParts: PlaceholderParts[] = textParts.map(
              (part, index) => {
                if (index === currentIndex) {
                  if (e.target.value === 'StudioStaticTextPart') {
                    return {
                      __typename: 'StudioPlainTextPart',
                      text: textPart.text,
                      textIntent: textPart.textIntent,
                      allowBlank: true,
                      allowDefault: true,
                      textTransform: StudioTextTransform.NONE,
                      maxCharacters: null,
                      // Custom number may not be needed
                      customNo: Number(selectedElementId),
                    }
                  }

                  return {
                    __typename: 'StudioStaticTextPart',
                    text: part.text,
                    textIntent: part.textIntent,
                  }
                }
                return part
              },
            )

            onUpdateElement({
              text: {
                __typename: 'StudioPlaceholderTextPart',
                textParts: updatedTextParts,
              },
            })
          }}
          value={textParts[currentIndex].__typename}
        />
      </FormControlLabel>

      {textPart.__typename === 'StudioPlainTextPart' && (
        <Box>
          <FormControlLabel
            label="Text only"
            id={`text-only-placeholder-${currentIndex}`}
            data-testid={`text-only-placeholder-${currentIndex}`}
          >
            <RadioButton
              id={`text-only-placeholder-${currentIndex}`}
              checked={textPart.__typename === 'StudioPlainTextPart'}
              onChange={() => {
                let updatedTextParts: PlaceholderParts[] = textParts.map(
                  (part, index) => {
                    if (index === currentIndex) {
                      return {
                        __typename: 'StudioPlainTextPart',
                        text: textPart.text,
                        textIntent: textPart.textIntent,
                        allowBlank: true,
                        allowDefault: true,
                        textTransform: StudioTextTransform.NONE,
                        maxCharacters: null,
                        // Custom number may not be needed
                        customNo: Number(selectedElementId),
                      }
                    }
                    return part
                  },
                )
                onUpdateElement({
                  text: {
                    __typename: 'StudioPlaceholderTextPart',
                    textParts: updatedTextParts,
                  },
                })
              }}
              value="StudioPlainTextPart"
            />
          </FormControlLabel>
        </Box>
      )}
    </>
  )
}

export const PlaceholderTextOptions: FC<PlaceholderOptionsProps> = ({
  selectedTextPartIndex,
  textParts,
  onUpdateElement,
}) => {
  const parts = useMemo(() => {
    return textParts.map(part => {
      return {
        title: part.text,
        part: part,
        key: part.text,
      }
    })
  }, [textParts])

  const [selectedPart, setSelectedPart] = useState<number>(
    selectedTextPartIndex && parts[selectedTextPartIndex]
      ? selectedTextPartIndex
      : 0,
  )
  const part = parts[selectedPart]
  useEffect(() => {
    setSelectedPart(selectedTextPartIndex || 0)
  }, [part, selectedPart, selectedTextPartIndex])
  return (
    <Box width={'100%'}>
      {part && (
        <>
          {part.part.__typename === 'StudioStaticTextPart' ? (
            <PlaceholderStaticTextPart
              onUpdateElement={onUpdateElement}
              index={selectedPart}
              part={part.part}
              textParts={textParts}
            />
          ) : (
            <PlaceholderDynamicTextPart
              onUpdateElement={onUpdateElement}
              index={selectedPart}
              part={part.part}
              textParts={textParts}
            />
          )}
        </>
      )}
    </Box>
  )
}

type PlaceholderStaticTextPartProps = {
  textParts: PlaceholderParts[]
  part: PlaceholderParts
  index: number
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

const PlaceholderStaticTextPart: FC<PlaceholderStaticTextPartProps> = ({
  textParts,
  index,
  onUpdateElement,
}) => {
  return (
    <>
      <PlaceholderRadioGroup
        onUpdateElement={onUpdateElement}
        textParts={textParts}
        currentIndex={index}
      />
    </>
  )
}

const PlaceholderDynamicTextPart: FC<{
  textParts: PlaceholderParts[]
  part: StudioPlainTextPart
  index: number
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}> = ({ textParts, part, index, onUpdateElement }) => {
  return (
    <>
      <PlaceholderRadioGroup
        onUpdateElement={onUpdateElement}
        textParts={textParts}
        currentIndex={index}
      />
      <PlaceholderPlainTextOptions
        onUpdateElement={onUpdateElement}
        textParts={textParts}
        textPart={part}
        currentIndex={index}
      />
    </>
  )
}
