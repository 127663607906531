import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import polly from 'polly-js'

import { GET_TEMPLATE_GROUPS_BY_ID } from '../graphql/queries'
import {
  GetTemplateGroupsQuery,
  GetTemplateGroupsQueryVariables,
} from '../__graphql__/queries'
import { Maybe, StudioGroupTemplate } from '../__graphql__/types'

export type GetTemplateGroupsService = (
  ids: string[],
) => Promise<Maybe<Array<Maybe<StudioGroupTemplate>>>>

export const useFetchTemplateGroupsByIds = (): GetTemplateGroupsService => {
  const client = useApolloClient()

  return useCallback(
    async ids => {
      return polly()
        .waitAndRetry(5)
        .executeForPromise(async () => {
          const { data, error } = await client.query<
            GetTemplateGroupsQuery,
            GetTemplateGroupsQueryVariables
          >({
            query: GET_TEMPLATE_GROUPS_BY_ID,
            errorPolicy: 'all',
            variables: {
              ids,
            },
          })

          if (error) {
            throw new Error(error?.message)
          }

          if (
            Array.isArray(data.studioTemplateGroupsByIds) &&
            data.studioTemplateGroupsByIds.length > 0
          ) {
            return data.studioTemplateGroupsByIds
          }

          return []
        })
    },
    [client],
  )
}
