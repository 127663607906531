import React, { FC, useEffect } from 'react'
import {
  StudioElement,
  StudioPlaceholderTextPart,
  StudioStaticTextPart,
  StudioTextElement,
} from '../../../types'
import { TextEditor } from './TextEditor'
import { CustomisationOptions } from './CustomisationOptions'
import { Box, Flex, SecondaryButton } from '@moonpig/launchpad-components'
import { StylingOptions } from './StylingOptions'
import { FormattingOptions } from './FormattingOptions'
import { isPlaceholderTextPart } from '../../../utils'
import { PlaceholderTextArea } from './PlaceholderTextArea'
import { buildTextPartsFromString } from '../../../utils/buildTextPartsFromString'
import { IntentOptions } from './IntentOptions'
import { useSceneProvider } from '../../../contexts/SceneProvider'

type TextControlsProps = {
  selectedElementId: string
  selectedElement: StudioTextElement
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

export const TextControls: FC<TextControlsProps> = ({
  selectedElementId,
  selectedElement,
  onUpdateElement,
}) => {
  const scene = useSceneProvider()
  const selectedTextPartIndex = scene.state.selectedElement?.textPartIndex!

  const convertTextToStatic = () => {
    const placeholder = selectedElement.text as StudioPlaceholderTextPart
    const text = placeholder.textParts.map(part => part.text).join('')

    const intents = placeholder.textParts.flatMap(part => part.textIntent)

    const newElement: StudioTextElement = {
      ...selectedElement,
      name: text,
      text: {
        __typename: 'StudioStaticTextPart',
        text,
        textIntent: intents,
      },
    }
    onUpdateElement(newElement)
  }

  const convertTextToTemplate = () => {
    const newElement: StudioTextElement = {
      ...selectedElement,
      text: {
        ...buildTextPartsFromString(
          (selectedElement.text as StudioStaticTextPart).text,
          selectedElement.text,
        ),
      },
    }
    onUpdateElement(newElement)
  }

  const [canConvertToTemplate, setCanConvertToTemplate] =
    React.useState<boolean>(false)

  useEffect(() => {
    setCanConvertToTemplate(
      !isPlaceholderTextPart(selectedElement.text) &&
        /.*\[.*].*/.test(selectedElement.text.text),
    )
  }, [selectedElement, selectedElement.text])

  return (
    <Flex flexDirection={'column'} margin={'16px'}>
      {!isPlaceholderTextPart(selectedElement.text) && (
        <TextEditor selectedElementId={selectedElementId} />
      )}
      {isPlaceholderTextPart(selectedElement.text) && (
        <PlaceholderTextArea
          selectedTextPartIndex={selectedTextPartIndex}
          textParts={selectedElement.text.textParts}
          onUpdateElement={onUpdateElement}
        />
      )}
      <Box width={1}>
        {isPlaceholderTextPart(selectedElement.text) && (
          <SecondaryButton
            data-testid="convert-text-to-static-button"
            width={1}
            onClick={convertTextToStatic}
          >
            Convert to fixed text
          </SecondaryButton>
        )}
        {!isPlaceholderTextPart(selectedElement.text) && (
          <SecondaryButton
            title={canConvertToTemplate ? '' : 'Text must contain [ ]'}
            data-testid="convert-text-to-placeholder-button"
            width={1}
            onClick={convertTextToTemplate}
            disabled={!canConvertToTemplate}
            style={{ marginTop: '16px' }}
          >
            Convert to placeholder text
          </SecondaryButton>
        )}
      </Box>
      <IntentOptions
        selectedTextPartIndex={selectedTextPartIndex}
        onUpdateElement={onUpdateElement}
        selectedElement={selectedElement}
      />
      <CustomisationOptions
        selectedTextPartIndex={selectedTextPartIndex}
        onUpdateElement={onUpdateElement}
        selectedElement={selectedElement}
      />
      <FormattingOptions
        onUpdateElement={onUpdateElement}
        selectedElement={selectedElement}
        selectedElementId={selectedElementId}
      />

      <StylingOptions
        onUpdateElement={onUpdateElement}
        selectedElement={selectedElement}
      />
    </Flex>
  )
}
