import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { GET_GROUP_BY_PATH } from '../graphql/queries'
import { StudioGroup } from '../types'
import {
  GetGroupByPathQuery,
  GetGroupByPathQueryVariables,
} from '../__graphql__/queries'

export type FetchGroupByPathService = (path: string) => Promise<StudioGroup>

export const useFetchGroupByPath = (): FetchGroupByPathService => {
  const client = useApolloClient()

  return useCallback(
    async (path: string) => {
      const { data } = await client.query<
        GetGroupByPathQuery,
        GetGroupByPathQueryVariables
      >({
        errorPolicy: 'all',
        query: GET_GROUP_BY_PATH,
        fetchPolicy: 'network-only',
        variables: {
          path,
        },
      })

      if (data.studioGroupByPath.__typename === 'StudioGroup') {
        return data.studioGroupByPath
      }

      throw new Error(data.studioGroupByPath.__typename)
    },
    [client],
  )
}
