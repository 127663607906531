import { assign, createMachine, DoneInvokeEvent } from 'xstate'
import {
  Context,
  DeselectTemplateEvent,
  Event,
  SaveAsErrorEvent,
  SelectGroupEvent,
  SelectNodeEvent,
  SelectTemplateEvent,
  States,
} from '../types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const forEvent =
  <TEvent extends Event | DoneInvokeEvent<any>, TReturn = any>(
    handler: (context: Context, event: TEvent) => TReturn,
  ): ((context: Context, event: Event) => TReturn) =>
  (context, event) =>
    handler(context, event as TEvent)

const isSaveAsErrorEvent = (
  unknownEvent: any,
): unknownEvent is SaveAsErrorEvent => {
  return unknownEvent?.data?.message
}

const initialContext: Context = {
  preserveAspectRatio: false,
  selectedTemplateId: null,
  selectedPath: null,
  selectedMovingNode: null,
  selectedNode: null,
  hasFetchError: false,
  signedImageUrls: [],
  signedMaskUrls: [],
  addNotification: () => {},
  dismissAllNotifications: () => {},
  template: null,
}

const templateExplorerStates = {
  TEMPLATE_EXPLORER: {
    initial: 'LOADING_GROUPS',
    on: {
      CLOSE_TEMPLATE_EXPLORER: {
        target: '#UI.STUDIO',
      },
    },
    states: {
      LOADING_GROUPS: {
        on: {
          SELECT_TEMPLATE: {
            target: '#UI.LOADING_TEMPLATE',
            actions: ['selectTemplate'],
          },
          SELECT_NODE: {
            target: '#UI.LOADING_NODE',
            actions: ['selectNode'],
          },
        },
        invoke: {
          src: 'fetchTemplateData',
          onDone: {
            target: '#UI.TEMPLATE_EXPLORER.GROUP_SELECTION',
          },
          onError: {
            target: '#UI.TEMPLATE_EXPLORER.GROUPS_LOAD_ERROR',
          },
        },
      },
      NODE_LOAD_ERROR: {
        on: {
          CHANGE_TEMPLATE: {
            target: '#UI.TEMPLATE_EXPLORER',
          },
        },
      },
      LOADING_GROUP: {
        on: {
          SELECT_TEMPLATE: {
            target: '#UI.LOADING_TEMPLATE',
            actions: ['selectTemplate'],
          },
        },
        invoke: {
          src: 'fetchGroupByPath',
          onDone: {
            target: '#UI.TEMPLATE_EXPLORER.GROUP_SELECTION',
          },
          onError: {
            target: '#UI.TEMPLATE_EXPLORER.GROUP_LOAD_ERROR',
          },
        },
      },
      GROUPS_LOAD_ERROR: {
        on: {
          SELECT_TEMPLATE: {
            target: '#UI.LOADING_TEMPLATE',
            actions: ['selectTemplate'],
          },
        },
      },
      CLEANING_LOAD_ERROR: {
        invoke: {
          src: 'clearFetchError',
        },
        on: {
          SELECT_GROUP: {
            target: '#UI.TEMPLATE_EXPLORER.LOADING_GROUP',
            actions: ['selectPath'],
          },
          SELECT_TEMPLATE: {
            target: '#UI.LOADING_TEMPLATE',
            actions: ['selectTemplate'],
          },
        },
      },
      CLEAN_LOAD_ERROR: {
        onDone: {
          target: '#UI.TEMPLATE_EXPLORER',
        },
      },
      GROUP_LOAD_ERROR: {
        invoke: {
          src: 'setHasError',
        },
        on: {
          SELECT_TEMPLATE: {
            target: '#UI.LOADING_TEMPLATE',
            actions: ['selectTemplate'],
          },
          CLEAN_LOAD_ERROR: {
            target: '#UI.TEMPLATE_EXPLORER.CLEANING_LOAD_ERROR',
          },
        },
      },
      GROUP_SELECTION: {
        initial: 'SELECTING_GROUP',
        states: {
          SELECTING_GROUP: {},
          // @ts-ignore-next
          CREATE_GROUP: {},
        },
        on: {
          SELECT_TEMPLATE: {
            target: '#UI.LOADING_TEMPLATE',
            actions: ['selectTemplate'],
          },
          SELECT_GROUP: {
            target: '#UI.TEMPLATE_EXPLORER.LOADING_GROUP',
            actions: ['selectPath'],
          },
          CREATE_NEW_GROUP: {
            target: '#UI.TEMPLATE_EXPLORER.GROUP_SELECTION.CREATE_GROUP',
          },
        },
      },
    },
  },
}

const updateStates = {
  UPDATE_TEMPLATE: {
    initial: 'SAVE_UPDATE',
    states: {
      SAVE_UPDATE: {
        entry: ['notifySaving'],
        invoke: {
          src: 'updateTemplate',
          onDone: [
            {
              target: '#UI.STUDIO.UPDATE_TEMPLATE.UPLOAD_IMAGES',
              cond: 'uploadRequired',
            },
            {
              target:
                '#UI.STUDIO.UPDATE_TEMPLATE.WAIT_FOR_TEMPLATE_TO_BE_READY',
              cond: 'uploadNotRequired',
            },
          ],
          onError: {
            target: '#UI.STUDIO.UPDATE_TEMPLATE.UPDATE_TEMPLATE_ERROR',
          },
        },
      },
      UPLOAD_IMAGES: {
        entry: ['notifyUploading'],
        invoke: {
          src: 'uploadImages',
          onDone: {
            target: '#UI.STUDIO.UPDATE_TEMPLATE.WAIT_FOR_TEMPLATE_TO_BE_READY',
          },
          onError: {
            target: '#UI.STUDIO.UPDATE_TEMPLATE.UPDATE_TEMPLATE_ERROR',
          },
        },
      },
      WAIT_FOR_TEMPLATE_TO_BE_READY: {
        entry: ['notifyProcessingTemplate'],
        invoke: {
          src: 'templateStatus',
          onDone: {
            target: '#UI.STUDIO.UPDATE_TEMPLATE.UPDATE_TEMPLATE_COMPLETE',
          },
          onError: {
            target: '#UI.STUDIO.UPDATE_TEMPLATE.UPDATE_TEMPLATE_ERROR',
          },
        },
      },
      UPDATE_TEMPLATE_COMPLETE: {
        entry: ['notifyComplete'],
        always: [
          {
            target: '#UI.LOADING_TEMPLATE',
          },
        ],
      },
      UPDATE_TEMPLATE_ERROR: {
        entry: ['notifyUpdateError'],
        always: [
          {
            target: '#UI.STUDIO.NAVIGATING',
          },
        ],
      },
    },
  },
}

const studioStates = {
  STUDIO: {
    initial: 'NAVIGATING',
    on: {
      CHANGE_TEMPLATE: {
        target: '#UI.TEMPLATE_EXPLORER',
      },
      SELECT_TEMPLATE: {
        target: '#UI.LOADING_TEMPLATE',
        actions: ['selectTemplate'],
      },
      UPDATE_TEMPLATE: {
        target: '#UI.STUDIO.UPDATE_TEMPLATE',
      },
      PREVIEW: {
        target: '#UI.STUDIO.PREVIEWING',
      },
      DESELECT_TEMPLATE: {
        actions: ['deselectTemplate'],
      },
    },
    states: {
      NAVIGATING: {},
      PREVIEWING: {
        on: {
          EDIT: {
            target: '#UI.STUDIO.NAVIGATING',
          },
        },
      },
      ...updateStates,
    },
  },
}

export const stateMachine = createMachine<Context, Event, States>(
  {
    id: 'UI',
    initial: 'TEMPLATE_EXPLORER',
    context: initialContext,
    predictableActionArguments: true,
    invoke: [
      {
        src: 'fetchFonts',
      },
      {
        src: 'fetchIntents',
      },
    ],
    states: {
      ...templateExplorerStates,
      LOADING_TEMPLATE: {
        invoke: {
          src: 'fetchTemplate',
          onDone: {
            target: '#UI.STUDIO',
          },
          onError: {
            target: '#UI.TEMPLATE_LOAD_ERROR',
          },
        },
      },
      TEMPLATE_LOAD_ERROR: {
        entry: ['deselectTemplate', 'notifyTemplateLoadError'],
        on: {
          CHANGE_TEMPLATE: {
            target: '#UI.TEMPLATE_EXPLORER',
          },
        },
      },
      NODE_SELECTION: {
        on: {
          CHANGE_TEMPLATE: {
            target: '#UI.TEMPLATE_EXPLORER',
          },
          SELECT_TEMPLATE: {
            target: '#UI.LOADING_TEMPLATE',
            actions: ['selectTemplate'],
          },
        },
      },
      LOADING_NODE: {
        invoke: {
          src: 'fetchPath',
          onDone: {
            target: '#UI.NODE_SELECTION',
          },
          onError: {
            target: '#UI.NODE_NOT_FOUND_ERROR',
          },
        },
      },
      NODE_NOT_FOUND_ERROR: {
        entry: ['deselectTemplate'],
        on: {
          CHANGE_TEMPLATE: {
            target: '#UI.TEMPLATE_EXPLORER',
          },
        },
      },
      ...studioStates,
    },
  },
  {
    guards: {
      uploadRequired: context => {
        return (
          context.signedImageUrls.length > 0 ||
          context.signedMaskUrls.length > 0
        )
      },
      uploadNotRequired: context => {
        return (
          context.signedImageUrls.length === 0 &&
          context.signedMaskUrls.length === 0
        )
      },
      isDuplicateNameError: (_, evt) => {
        if (!isSaveAsErrorEvent(evt)) {
          return false
        }
        return evt.data.message === 'StudioDuplicateNameError'
      },
    },
    actions: {
      notifyProcessingTemplate: context => {
        context.addNotification({
          id: 'SAVING',
          text: 'Processing Template',
          variant: 'info',
          icon: 'loading',
          dismissible: false,
        })
      },
      notifyUploading: context => {
        context.addNotification({
          id: 'SAVING',
          text: 'Uploading Images',
          variant: 'info',
          icon: 'loading',
          dismissible: false,
        })
      },
      notifySaving: context => {
        context.addNotification({
          id: 'SAVING',
          text: 'Saving',
          variant: 'info',
          icon: 'loading',
          dismissible: false,
        })
      },
      notifyUpdateError: context => {
        context.addNotification({
          id: 'SAVING',
          text: 'Update Failed',
          variant: 'error',
        })
      },
      notifyComplete: context => {
        context.addNotification({
          id: 'SAVING',
          text: 'Update Complete',
          variant: 'success',
        })
      },
      notifyTemplateLoadError: context => {
        context.addNotification({
          id: 'TEMPLATE_LOAD',
          text: 'There was an error loading this template. Try refreshing the page or raise an issue in #cards-studio.',
          variant: 'error',
        })
      },
      selectPath: assign(
        forEvent<SelectGroupEvent>((_context, event) => {
          return {
            selectedPath: event.path,
          }
        }),
      ),
      selectTemplate: assign(
        forEvent<SelectTemplateEvent>((_context, event) => {
          return {
            selectedTemplateId: event.id,
          }
        }),
      ),
      selectNode: assign(
        forEvent<SelectNodeEvent>((_context, event) => {
          return {
            selectedPath: event.path,
          }
        }),
      ),
      deselectTemplate: assign(
        forEvent<DeselectTemplateEvent>(() => {
          return {
            selectedTemplateId: null,
          }
        }),
      ),
    },
  },
)
