import { FC, useMemo } from 'react'
import { Box, Text, Chip } from '@moonpig/launchpad-components'
import { StudioTextElement, StudioElement } from '../../../types'
import { Select, MenuItem, ListSubheader } from '@mui/material'
import { useIntentProvider } from '../../../contexts/IntentProvider'
import styled from 'styled-components'

type IntentOptionsProps = {
  selectedElement: StudioTextElement
  selectedTextPartIndex: number | null
  onUpdateElement: (elementProperties: Partial<StudioElement>) => void
}

const StyledSelect = styled(Select)`
  font-family: 'Moonpig, Arial, sans-serif';
  div {
    color: rgb(0, 84, 200);
    font-family: Moonpig;
    font-weight: 800;
    width 250px;

  }
`

const StyledChip = styled(Chip)`
  font-size: 12px;
  border: 2px solid;
  margin-top: 8px;
  margin-bottom: 4px;
  padding: 8px;
  margin: 0px;

  svg {
    width: 18px;
    height: 18px;
    align-self: center;
  }
`
const StyledListSubheader = styled(ListSubheader)`
  &.MuiListSubheader-root {
    font-family: 'Moonpig', Arial, sans-serif;
    background-color: #f8f8f8;
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
  }
`

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    font-family: 'Moonpig', Arial, sans-serif;
  }
`
export const IntentOptions: FC<IntentOptionsProps> = ({
  selectedTextPartIndex,
  selectedElement,
  onUpdateElement,
}) => {
  const intentProvider = useIntentProvider()

  const selectedOptions = useMemo<string[]>(() => {
    if (selectedElement.text.__typename === 'StudioPlaceholderTextPart') {
      if (selectedTextPartIndex === null) {
        return []
      }

      const textpartIntent = selectedElement.text.textParts[
        selectedTextPartIndex
      ]?.textIntent.map(intent =>
        [intent.intent, intent.format].filter(Boolean).join(':'),
      )

      return textpartIntent ?? []
    }

    return selectedElement.text.textIntent.map(intent =>
      [intent.intent, intent.format].filter(Boolean).join(':'),
    )
  }, [selectedElement, selectedTextPartIndex])

  const handleChange = (value: string) => {
    const [intent, format] = value.split(':')
    if (selectedTextPartIndex === null) {
      return
    }

    if (selectedElement.text.__typename === 'StudioPlaceholderTextPart') {
      if ('textParts' in selectedElement.text) {
        const textParts = [...selectedElement.text.textParts]
        const selectedTextPart = { ...textParts[selectedTextPartIndex] }
        const existingIntentIndex = selectedTextPart.textIntent?.findIndex(
          o => o.intent === intent,
        )

        if (existingIntentIndex !== undefined && existingIntentIndex !== -1) {
          const existingFormat =
            selectedTextPart.textIntent?.[existingIntentIndex].format

          if (existingFormat === format || existingFormat == null) {
            selectedTextPart.textIntent?.splice(existingIntentIndex, 1)
          } else {
            selectedTextPart.textIntent?.splice(existingIntentIndex, 1, {
              __typename: 'StudioTextIntent',
              intent: intent,
              format: format,
            })
          }
        } else {
          selectedTextPart.textIntent = [
            ...(selectedTextPart.textIntent || []),
            {
              __typename: 'StudioTextIntent',
              intent: intent,
              format: format,
            },
          ]
        }
        textParts[selectedTextPartIndex] = selectedTextPart

        onUpdateElement({
          text: {
            __typename: 'StudioPlaceholderTextPart',
            textParts,
          },
        })
        return
      }
    }

    const existingIntent = selectedElement.text.textIntent.findIndex(
      o => o.intent === intent,
    )

    if (existingIntent !== undefined && existingIntent !== -1) {
      const existingFormat =
        selectedElement.text.textIntent[existingIntent].format

      if (existingFormat === format || existingFormat == null) {
        selectedElement.text.textIntent.splice(existingIntent, 1)
      } else {
        selectedElement.text.textIntent.splice(existingIntent, 1, {
          __typename: 'StudioTextIntent',
          intent: intent,
          format: format,
        })
      }
    } else {
      selectedElement.text.textIntent.push({
        __typename: 'StudioTextIntent',
        intent: intent,
        format: format,
      })
    }

    onUpdateElement({
      text: {
        ...selectedElement.text,
        textIntent: Array.from(selectedElement.text.textIntent),
      },
    })
  }

  const intentOptions = useMemo(() => {
    return intentProvider.state.intents.map(intent => ({
      value: intent.key,
      label: intent.value,
      disabled: intent.disabled,
      formats: intent.formats,
    }))
  }, [intentProvider.state.intents])

  const isReady = intentOptions.length > 0

  const formatSelectedIntentChip = (o: string) => {
    const [intentKey, formatKey] = o.split(':')
    const intent = intentProvider.state.intents.find(i => i.key === intentKey)
    if (!intent) {
      return 'Unknown Intent'
    }

    if (!intent.formats || intent.formats.length < 2) {
      return intent.value
    }

    const format = intent.formats.find(f => f.key === formatKey)
    if (!format) {
      return 'Unknown Format'
    }

    return `${intent.value}:${format.value}`
  }

  return (
    <>
      {isReady && (
        <Box paddingTop={8}>
          <Text style={{ fontWeight: '800' }}>Intent Options</Text>
          <Box
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '4px',
              paddingTop: '12px',
              marginBottom: '16px',
            }}
            data-testid="selected-intents"
          >
            {selectedOptions &&
              selectedOptions.map((o, i) => {
                return (
                  <StyledChip
                    selected
                    dismissible={true}
                    key={`selected-intent-${i}`}
                    onClick={() => handleChange(o)}
                  >
                    {formatSelectedIntentChip(o)}
                  </StyledChip>
                )
              })}

            <StyledSelect
              name="Add Intents"
              label=""
              multiple
              displayEmpty
              renderValue={() => 'Add Intents'}
              value={selectedOptions}
              data-testid="intent-options"
            >
              {intentOptions.map(option => {
                if (option.formats.length === 0) {
                  return (
                    <StyledMenuItem
                      dense
                      onClick={() => handleChange(option.value)}
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </StyledMenuItem>
                  )
                }

                if (option.formats.length > 1) {
                  const components: any = [
                    <StyledListSubheader>{option.label}</StyledListSubheader>,
                  ]

                  option.formats.forEach(f =>
                    components.push(
                      <StyledMenuItem
                        onClick={() => handleChange(`${option.value}:${f.key}`)}
                        dense
                        key={f.key}
                        selected={true}
                        value={`${option.value}:${f.key}`}
                      >
                        {f.value}
                      </StyledMenuItem>,
                    ),
                  )

                  return components
                }

                const optionEntry = `${option.value}:${option.formats[0].key}`
                return (
                  <StyledMenuItem
                    dense
                    onClick={() => handleChange(optionEntry)}
                    key={optionEntry}
                    value={optionEntry}
                  >
                    {option.label}
                  </StyledMenuItem>
                )
              })}
            </StyledSelect>
          </Box>
        </Box>
      )}
    </>
  )
}
