import polly from 'polly-js'
import { StudioNode } from '../types'
import { ApolloClient, useApolloClient } from '@apollo/client'
import { DELETE_GROUP, DELETE_TEMPLATE } from '../graphql/mutations'
import { DeleteNodeError } from '../machines/delete-modal-machine'
import {
  DeleteGroupMutation,
  DeleteGroupMutationVariables,
  DeleteTemplateMutation,
  DeleteTemplateMutationVariables,
} from '../__graphql__/mutations'

type DeleteNodeServiceResult = { type: 'unknown' } | { type: 'success' }

export type DeleteNodeService = (
  node: StudioNode,
) => Promise<DeleteNodeServiceResult>

const shouldNotRetryErrors: string[] = [
  'StudioTemplateIsMappedError',
  'StudioDeleteNonEmptyGroupError',
]

const shouldRetry = (err: Error) => {
  return !shouldNotRetryErrors.includes(err.name)
}

const deleteTemplate = async (
  client: ApolloClient<object>,
  node: StudioNode,
): Promise<DeleteNodeServiceResult> => {
  return polly()
    .handle(shouldRetry)
    .waitAndRetry(5)
    .executeForPromise(async () => {
      const { data } = await client.mutate<
        DeleteTemplateMutation,
        DeleteTemplateMutationVariables
      >({
        errorPolicy: 'all',
        mutation: DELETE_TEMPLATE,
        variables: {
          input: {
            template: {
              id: node.id,
            },
          },
        },
      })

      if (data) {
        if (data.deleteTemplate.__typename === 'StudioDeletedTemplate') {
          return { type: 'success' }
        }

        if (data.deleteTemplate.__typename === 'StudioTemplateIsMappedError') {
          throw new DeleteNodeError(
            data.deleteTemplate.__typename,
            data.deleteTemplate.message,
            data.deleteTemplate.products,
          )
        }

        throw new DeleteNodeError(
          data.deleteTemplate.__typename,
          data.deleteTemplate.message,
        )
      }

      throw new Error('Something went wrong!')
    })
}

const deleteGroup = async (
  client: ApolloClient<object>,
  node: StudioNode,
): Promise<DeleteNodeServiceResult> => {
  return polly()
    .handle(shouldRetry)
    .waitAndRetry(5)
    .executeForPromise(async () => {
      const { data } = await client.mutate<
        DeleteGroupMutation,
        DeleteGroupMutationVariables
      >({
        errorPolicy: 'all',
        mutation: DELETE_GROUP,
        variables: {
          input: {
            group: {
              id: node.id,
            },
          },
        },
      })

      if (data) {
        if (data.deleteGroup.__typename === 'StudioDeletedGroup') {
          return { type: 'success' }
        }

        throw new DeleteNodeError(
          data.deleteGroup.__typename,
          data.deleteGroup.message,
        )
      }

      throw new Error('Something went wrong! Please try again.')
    })
}

export const useDeleteNode = (): DeleteNodeService => {
  const client = useApolloClient()

  return async (node: StudioNode) => {
    if (node.__typename === 'StudioGroupTemplate') {
      return deleteTemplate(client, node)
    }

    if (node.__typename === 'StudioGroup') {
      return deleteGroup(client, node)
    }

    return { type: 'unknown' }
  }
}
