import { ApolloClient } from '@apollo/client'
import polly from 'polly-js'
import { CREATE_TEMPLATE_IMPORT } from '../../graphql/mutations'
import { StudioCreatedImport, StudioGroup, StudioUser } from '../../types'
import {
  CREATE_PSD_IMPORT_ATTEMPTS,
  PHOTOSHOP_MIME_TYPES,
  TEMPLATE_NAME_VALIDATION_REGEX,
  UPLOAD_PSD_ATTEMPTS,
} from '../../constants'
import { TEMPLATE_FILENAME_ERROR } from '../../hooks/useErrorMessages'
import { validateFileSize } from '../../utils/validators/file-size'
import {
  CreateTemplateImportMutation,
  CreateTemplateImportMutationVariables,
} from '../../__graphql__/mutations'

export type UploadService = {
  validate: ValidateHandler
  upload: UploadHandler
}

export type CreateUploadService = (
  client: ApolloClient<object>,
  user: StudioUser,
) => UploadService

export type ValidateHandler = (file: File) => Promise<boolean>

export type UploadHandler = (
  file: File,
  currentGroup: StudioGroup,
) => Promise<StudioCreatedImport>

const shouldRetryError = (err: Error) => {
  return (
    ['StudioDuplicateNameError', 'StudioDuplicatePathError'].includes(
      err.name,
    ) === false
  )
}

export const createUploadService: CreateUploadService = (client, user) => {
  const validate: ValidateHandler = async file => {
    if (file.name.endsWith('.psd') === false) {
      throw new ImportUploadError(
        'StudioInvalidNameError',
        'Only PSD files are currently supported.',
      )
    }

    if (TEMPLATE_NAME_VALIDATION_REGEX.test(file.name) === false) {
      throw new ImportUploadError(
        'StudioInvalidNameError',
        TEMPLATE_FILENAME_ERROR,
      )
    }

    if (validateFileSize(file.size, 100) === false) {
      throw new ImportUploadError(
        'StudioFileSizeTooLargeError',
        'File size is too large, Max Size is 100mb.',
      )
    }

    return true
  }
  const upload: UploadHandler = async (file, currentGroup) => {
    const templateName = file.name
      .substring(0, file.name.indexOf('.psd'))
      .trim()

    const data = await polly()
      .handle(shouldRetryError)
      .waitAndRetry(CREATE_PSD_IMPORT_ATTEMPTS)
      .executeForPromise(async () => {
        const { data } = await client.mutate<
          CreateTemplateImportMutation,
          CreateTemplateImportMutationVariables
        >({
          errorPolicy: 'all',
          mutation: CREATE_TEMPLATE_IMPORT,
          variables: {
            templateName,
            groupPath: currentGroup.path,
            createdBy: user.email,
          },
        })

        if (
          data &&
          data.createTemplateImport.__typename === 'StudioTemplateCreateImport'
        ) {
          return data.createTemplateImport
        }

        if (
          data?.createTemplateImport.__typename === 'StudioDuplicateNameError'
        ) {
          throw new ImportUploadError(
            data.createTemplateImport.__typename,
            'Template name already exists.',
          )
        }

        if (
          data?.createTemplateImport.__typename === 'StudioDuplicatePathError'
        ) {
          throw new ImportUploadError(
            data.createTemplateImport.__typename,
            'Template already exists at this location.',
          )
        }

        if (
          data &&
          data.createTemplateImport.__typename === 'StudioImportError'
        ) {
          throw new ImportUploadError(
            data.createTemplateImport.__typename,
            data.createTemplateImport.message,
          )
        }

        throw new ImportUploadError(
          'StudioImportError',
          'Something has gone wrong',
        )
      })

    await polly()
      .waitAndRetry(UPLOAD_PSD_ATTEMPTS)
      .executeForPromise(async () => {
        const { signedUrl } = data

        const response = await fetch(signedUrl, {
          method: 'PUT',
          headers: { 'Content-Type': PHOTOSHOP_MIME_TYPES[0] },
          body: file,
        })

        if (response.status > 200) {
          throw new Error(`${response.status}: ${response.statusText}`)
        }
      })

    return data
  }

  return { validate, upload }
}

export class ImportUploadError extends Error {
  public readonly error: string | undefined

  constructor(
    name:
      | 'StudioDuplicateNameError'
      | 'StudioDuplicatePathError'
      | 'StudioInvalidNameError'
      | 'StudioFileSizeTooLargeError'
      | 'StudioImportError',
    message: string,
    error?: string | undefined,
  ) {
    super(message)

    this.name = name
    this.error = error
  }
}
