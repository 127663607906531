import { FC } from 'react'
import { InteractiveFrame } from '../InteractiveFrame'
import { useSceneProvider } from '../../contexts/SceneProvider'

type InteractiveRectangleProps = {
  id: string
  unitsPerPixel: number
  x: number
  y: number
  width: number
  height: number
  rotation: number
}

export const InteractiveRectangle: FC<InteractiveRectangleProps> = ({
  id,
  x,
  y,
  width,
  height,
  rotation,
}) => {
  const scene = useSceneProvider()
  const selectedElementId = scene.state.selectedElement?.id
  const isSelected = id === selectedElementId

  return (
    <InteractiveFrame
      key={id}
      ariaLabel="Edit Rectangle"
      selected={isSelected}
      onClick={() => {
        scene.actions.selectElement(id)
      }}
      frame={{ x, y, width, height, rotation }}
    />
  )
}
