import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import polly from 'polly-js'
import { CREATE_TEMPLATE_GROUP } from '../graphql/mutations'
import {
  CreateTemplateGroupMutation,
  CreateTemplateGroupMutationVariables,
} from '../__graphql__/mutations'
import { StudioCreateTemplateGroupResult } from '../__graphql__/types'

export type CreateTemplateGroupService = (
  name: string,
  parentPath: string,
) => Promise<StudioCreateTemplateGroupResult>

const shouldRetry = (err: Error) => {
  return !err.message.includes('StudioDuplicatePathError')
}

export const useCreateTemplateGroup = (): CreateTemplateGroupService => {
  const client = useApolloClient()

  return useCallback(
    async (name, parentPath) => {
      return polly()
        .handle(shouldRetry)
        .waitAndRetry(5)
        .executeForPromise(async () => {
          const group = {
            name,
            parentPath,
            path: parentPath.concat(`>${name}`),
          }
          const { data } = await client.mutate<
            CreateTemplateGroupMutation,
            CreateTemplateGroupMutationVariables
          >({
            mutation: CREATE_TEMPLATE_GROUP,
            errorPolicy: 'all',
            variables: {
              input: {
                group,
              },
            },
          })

          if (data) {
            if (
              data.createTemplateGroup?.__typename ===
              'StudioCreatedTemplateGroup'
            ) {
              return data.createTemplateGroup
            }

            throw new Error(data.createTemplateGroup.__typename)
          }

          throw new Error('StudioCreatedTemplateGroupUnknownError')
        })
    },
    [client],
  )
}
