import React, { FC } from 'react'
import {
  StudioPlaceholderTextPart,
  StudioTextElement,
} from '../../__graphql__/types'
import { Flex } from '@moonpig/launchpad-components'
import { isPlaceholderTextPart } from '../../utils'
import {
  IconSystemBin,
  IconSystemPlusFilled,
  IconSystemTextField,
} from '@moonpig/launchpad-assets'
import { useElementData } from '../../data'
import { IconSystemPlaceholderTextField } from './img/IconSystemPlaceholderTextField'
import { colorValue } from '@moonpig/launchpad-theme-mission-control'
import { styled } from '@moonpig/launchpad-utils'
import { useSceneProvider } from '../../contexts/SceneProvider'

export interface TextElementPartControlsProps {
  element: StudioTextElement
  elementId: string
}

const StyledRemoveTextPartButton = styled(IconSystemBin)`
  cursor: pointer;
  &:hover path {
    fill: ${colorValue('colorBorder01')};
  }
`

const StyledAddTextPartButton = styled(IconSystemPlusFilled)`
  cursor: pointer;
  &:hover path {
    fill: ${colorValue('colorBorder01')};
  }
`

const StyledTextPartControls = styled.div`
  display: flex;
  border: 1px solid grey;
  padding: 6px 5px 5px;
  margin: 2px;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
  user-select: none;
  height: 40px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid black;
  }
`

export const TextElementPartControls: FC<TextElementPartControlsProps> = ({
  element,
  elementId,
}) => {
  const scene = useSceneProvider()
  const { updateElement } = useElementData(elementId)
  const selectedElementId = scene.state.selectedElement?.id
  const selectedElementPartIndex = scene.state.selectedElement?.textPartIndex

  const handleTextPartClick = (index: number) => {
    scene.actions.selectElement(elementId, index)
  }

  const handleRemoveTextPartClick = (
    e: React.MouseEvent,
    element: StudioTextElement,
    index: number,
  ) => {
    e.stopPropagation()

    const placeholderElement = element.text as StudioPlaceholderTextPart
    const textParts = placeholderElement.textParts

    if (textParts.length === 1) {
      const newElement: StudioTextElement = {
        ...element,
        text: {
          __typename: 'StudioStaticTextPart',
          text: textParts[0].text,
          textIntent: [],
        },
      }

      updateElement(newElement)
    } else {
      const newElement = {
        text: {
          ...placeholderElement,
          textParts: [
            ...textParts.filter(
              (_textPart, textPartIndex) => textPartIndex !== index,
            ),
          ],
        },
      }

      scene.actions.selectElement(elementId, 0)

      updateElement(newElement)
    }
  }

  const handleAddTextPartClick = (
    e: React.MouseEvent,
    element: StudioTextElement,
    index: number,
  ) => {
    e.stopPropagation()

    const placeholderElement = element.text as StudioPlaceholderTextPart
    const textParts = placeholderElement.textParts

    const newElement = {
      text: {
        ...placeholderElement,
        textParts: [
          ...textParts.slice(0, index + 1),
          {
            text: 'PLACEHOLDER',
            __typename: 'StudioPlainTextPart',
            maxCharacters: 8,
            textTransform: 'NONE',
            allowDefault: true,
            allowBlank: true,
            customNo: textParts.length,
            textIntent: [],
          },
          ...textParts.slice(index + 1),
        ],
      },
    }

    updateElement(newElement as StudioTextElement)
  }

  const iconStyleRight = {
    minWidth: '20px',
    paddingTop: '4px',
    marginLeft: '-2px',
    marginRight: '1px',
  }

  const isSelectedElement = selectedElementId === elementId

  return (
    <Flex flexDirection="column">
      {isPlaceholderTextPart(element.text) &&
        element.text.textParts.map((textPart, index) => (
          <StyledTextPartControls
            data-testid={`text-part-controls-${elementId}-${index}`}
            style={{
              backgroundColor:
                isSelectedElement && selectedElementPartIndex === index
                  ? 'rgba(255,255,255,0.8)'
                  : 'transparent',
              border:
                isSelectedElement && selectedElementPartIndex === index
                  ? '2px solid black'
                  : '1px solid grey',
            }}
            key={`${element.text}_${textPart.__typename}_${index}`}
            onClick={e => {
              e.stopPropagation()
              handleTextPartClick(index)
            }}
          >
            {textPart.__typename === 'StudioPlainTextPart' && (
              <IconSystemPlaceholderTextField
                height="20px"
                width="20px"
                style={iconStyleRight}
              />
            )}
            {textPart.__typename !== 'StudioPlainTextPart' && (
              <IconSystemTextField
                height="20px"
                width="20px"
                style={iconStyleRight}
              />
            )}
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '180px',
                fontStyle:
                  textPart.__typename === 'StudioPlainTextPart'
                    ? 'italic'
                    : 'normal',
              }}
            >
              <span style={{ fontWeight: 'bold' }}>{index + 1}</span>:{' '}
              <span
                data-testid={`text-part-controls-content-${elementId}-${index}`}
              >
                {textPart.text}
              </span>
            </div>
            <div
              style={{
                minWidth: '48px',
              }}
            >
              <StyledRemoveTextPartButton
                height={'24px'}
                width={'24px'}
                data-testid={`remove-text-part-${elementId}-${index}`}
                onClick={e => handleRemoveTextPartClick(e, element, index)}
              />
              <StyledAddTextPartButton
                height={'24px'}
                width={'24px'}
                data-testid={`add-text-part-after-${elementId}-${index}`}
                onClick={e => handleAddTextPartClick(e, element, index)}
              />
            </div>
          </StyledTextPartControls>
        ))}
    </Flex>
  )
}
