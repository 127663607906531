import React, { createContext, FC, PropsWithChildren, useContext } from 'react'

type SetShowCustomisationsAction = {
  type: 'SET_SHOW_CUSTOMISATIONS'
}

type SetHideCustomisationsAction = {
  type: 'SET_HIDE_CUSTOMISATIONS'
}

type SetSelectedElementAction = {
  type: 'SET_SELECTED_ELEMENT'
  value?: { id: string; textPartIndex?: number }
}

type SetShowInlineEditingAction = {
  type: 'SET_SHOW_INLINE_EDITING'
}

type SetHideInlineEditingAction = {
  type: 'SET_HIDE_INLINE_EDITING'
}

type SetZoomAction = {
  type: 'SET_ZOOM'
  value: number
}

type SetShowFontAlternativesPopupAction = {
  type: 'SET_SHOW_FONT_ALTERNATIVES_POPUP'
}

type SetHideFontAlternativesPopupAction = {
  type: 'SET_HIDE_FONT_ALTERNATIVES_POPUP'
}

type Action =
  | SetShowCustomisationsAction
  | SetHideCustomisationsAction
  | SetShowFontAlternativesPopupAction
  | SetHideFontAlternativesPopupAction
  | SetZoomAction
  | SetSelectedElementAction
  | SetShowInlineEditingAction
  | SetHideInlineEditingAction

type ActionDispatch = (action: Action) => void

type State = {
  customisationsVisible: boolean
  zoom: number
  showFontAlternativesPopup: boolean
  selectedElement: { id: string; textPartIndex?: number } | undefined
  showInlineEditing: boolean
}

type Context =
  | {
      state: State
      dispatch: ActionDispatch
    }
  | undefined

const SceneContext = createContext<Context>(undefined)

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_SHOW_CUSTOMISATIONS':
      return {
        ...state,
        customisationsVisible: true,
      }
    case 'SET_HIDE_CUSTOMISATIONS':
      return {
        ...state,
        customisationsVisible: false,
      }
    case 'SET_ZOOM':
      return {
        ...state,
        zoom: action.value,
      }
    case 'SET_SELECTED_ELEMENT':
      return {
        ...state,
        selectedElement: action.value,
        showInlineEditing:
          state.selectedElement?.id !== undefined &&
          state.showInlineEditing === true &&
          action.value?.id === state.selectedElement?.id,
      }
    case 'SET_SHOW_FONT_ALTERNATIVES_POPUP':
      return {
        ...state,
        showFontAlternativesPopup: true,
      }
    case 'SET_HIDE_FONT_ALTERNATIVES_POPUP':
      return {
        ...state,
        showFontAlternativesPopup: false,
      }
    case 'SET_SHOW_INLINE_EDITING':
      return {
        ...state,
        showInlineEditing: true,
      }
    case 'SET_HIDE_INLINE_EDITING':
      return {
        ...state,
        showInlineEditing: false,
      }
    default:
      return state
  }
}

export const SceneProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    customisationsVisible: false,
    zoom: 1,
    showFontAlternativesPopup: false,
    selectedElement: undefined,
    showInlineEditing: false,
  })

  return (
    <SceneContext.Provider value={{ state, dispatch }}>
      {children}
    </SceneContext.Provider>
  )
}

export const useSceneProvider = () => {
  const context = useContext(SceneContext)

  if (context === undefined) {
    throw new Error('useSceneProvider must be used within a SceneProvider')
  }

  const { state, dispatch } = context

  const selectElement = (elementId: string, textPartIndex?: number) =>
    dispatch({
      type: 'SET_SELECTED_ELEMENT',
      value: { id: elementId, textPartIndex },
    })

  const deselectElement = () => dispatch({ type: 'SET_SELECTED_ELEMENT' })

  const actions = {
    selectElement,
    deselectElement,
  }

  return {
    state,
    dispatch,
    actions,
  }
}
