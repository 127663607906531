import { FC, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { LocaleTextProvider } from '@moonpig/launchpad-localisation'
import { styled, ThemeProvider } from '@moonpig/launchpad-utils'
import {
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  Text,
} from '@moonpig/launchpad-components'
import { studioTheme } from '../theme'
import { StudioEditor } from '../components/StudioEditor'
import {
  FeatureToggles,
  FeatureTogglesProvider,
  useFeatureToggle,
} from '../contexts/FeatureToggleProvider'
import { ServiceProvider } from '../services'
import { StudioUser } from '../types'
import { FormControlLabel, Toggle } from '@moonpig/launchpad-forms'

const StyledStudio = styled.div`
  position: fixed;
  display: flex;
  background-color: #f8f8f9;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const StyledHeader = styled.header`
  padding-top: 8px;
  padding-bottom: 8px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 64px;
`

const GlobalHeader: FC<{
  user: StudioUser
  debugToolBarEnabled: boolean
  setDebugToolBarEnabled: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ user, debugToolBarEnabled, setDebugToolBarEnabled }) => {
  const debugEnabled = useFeatureToggle('debug')
  return (
    <StyledHeader>
      <Container>
        <Grid flex alignItems={'center'}>
          <Box width={{ xs: 1, md: 2 / 3 }}>
            <Heading level="h1" mb={4} typography="typeDisplay02">
              Studio
            </Heading>
          </Box>
          <Flex
            width={{ xs: 1, md: 1 / 3 }}
            alignItems="center"
            justifyContent="flex-end"
          >
            {debugEnabled && (
              <FormControlLabel label="Show debug toolbar" labelPosition="left">
                <Toggle
                  checked={debugToolBarEnabled}
                  onChange={e => setDebugToolBarEnabled(e.target.checked)}
                  value="enabled"
                />
              </FormControlLabel>
            )}
            <Text>{user.name}</Text>
          </Flex>
        </Grid>
      </Container>
    </StyledHeader>
  )
}

export const StudioPageAuthenticated: FC<{
  user: StudioUser
  templateId: string
  logicalPath: string
}> = ({ user, templateId, logicalPath }) => {
  const [debugToolBarEnabled, setDebugToolBarEnabled] = useState(false)
  return (
    <LocaleTextProvider region="uk">
      <ThemeProvider theme={studioTheme}>
        <ServiceProvider>
          <StyledStudio>
            <GlobalHeader
              user={user}
              debugToolBarEnabled={debugToolBarEnabled}
              setDebugToolBarEnabled={setDebugToolBarEnabled}
            />
            <StudioEditor
              debugToolBarEnabled={debugToolBarEnabled}
              templateId={templateId}
              user={user}
              logicalPath={logicalPath}
            />
          </StyledStudio>
        </ServiceProvider>
      </ThemeProvider>
    </LocaleTextProvider>
  )
}

export const StudioPage: FC<{
  toggles: FeatureToggles
  templateId: string
  logicalPath: string
}> = ({ toggles, templateId, logicalPath }) => {
  const { authState } = useOktaAuth()

  if (!authState?.isAuthenticated) {
    return <h1>Not Authenticated</h1>
  }

  const user = {
    name: authState.idToken!.claims.name!,
    email: authState.idToken!.claims.email!,
  }

  return (
    <FeatureTogglesProvider featureToggles={toggles}>
      <StudioPageAuthenticated
        user={user}
        templateId={templateId}
        logicalPath={logicalPath}
      />
    </FeatureTogglesProvider>
  )
}
