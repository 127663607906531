import { FC, Fragment, RefObject, useCallback, useMemo, useRef } from 'react'
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd'
import { Flex, TertiaryButton, Text } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import {
  useSetTemplateElements,
  useTemplateElementsCollection,
} from '../../data'
import { StudioElementWithId } from '../../types'
import {
  LAYER_IMAGE_ACCEPTED_EXTENSIONS,
  SUPPORTED_UPLOAD_IMAGE_MAX_SIZE_BYTES,
} from '../../constants'
import { ImportInputButton } from '../ImportInputButton'
import { useErrorMessages } from '../../hooks'
import { IMAGE_UPLOAD_FAILED_ACCEPT_MESSAGE } from '../../strings'
import { ElementItem } from './ElementItem'
import { reorderElements } from '../../utils/reorderElements'
import { DropdownMenu, DropdownMenuItem } from '../Dropdown/Dropdown'
import {
  IconSystemChevronDown,
  IconSystemChevronUp,
} from '@moonpig/launchpad-assets'
import { useTemplateProvider } from '../../contexts/TemplateProvider'
import { useSceneProvider } from '../../contexts/SceneProvider'

const ELEMENT_SELECTION_CONTROLS_HEADER_HEIGHT = 48
const ELEMENT_SELECTION_CONTROLS_HEADER_SPACING = 16

const StyledScrollView = styled.div`
  height: calc(
    100% - ${ELEMENT_SELECTION_CONTROLS_HEADER_HEIGHT}px -
      ${ELEMENT_SELECTION_CONTROLS_HEADER_SPACING}px
  );
  max-height: 100%;
  overflow-y: auto;
`

const ImageUploadButton: FC<{
  inputRef: RefObject<HTMLInputElement>
}> = props => {
  const scene = useSceneProvider()
  const { createImageElement } = useSetTemplateElements()
  const { showImageSizeError } = useErrorMessages()

  return (
    <ImportInputButton
      inputRef={props.inputRef}
      validate
      id="std-image-input"
      failAcceptMessage={IMAGE_UPLOAD_FAILED_ACCEPT_MESSAGE}
      accept={LAYER_IMAGE_ACCEPTED_EXTENSIONS.join(',')}
      onChange={async fileList => {
        if (fileList && fileList.length > 0) {
          const file = Array.from(fileList!)[0]
          if (file.size > SUPPORTED_UPLOAD_IMAGE_MAX_SIZE_BYTES) {
            showImageSizeError()
            return
          }

          const id = await createImageElement(file)

          scene.actions.selectElement(id)
        }
      }}
    />
  )
}

const ElementSelectionControlsHeader = () => {
  const scene = useSceneProvider()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { createTextElement } = useSetTemplateElements()

  const handleAddTextElement = () => {
    const id = createTextElement()

    scene.actions.selectElement(id)
  }

  const handleAddImageElement = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <Flex justifyContent="space-between" alignItems="center" mb={6}>
      <Text typography="typeDisplay06" mb={0}>
        Layers
      </Text>

      <ImageUploadButton inputRef={fileInputRef} />

      <DropdownMenu
        trigger={({ isExpanded }) => (
          <TertiaryButton mb={0}>
            <Flex justifyContent="space-between" alignItems="center">
              <Text typography="bold1">Add</Text>
              <Flex ml={0}>
                {isExpanded ? (
                  <IconSystemChevronUp width={'25px'} height={'25px'} />
                ) : (
                  <IconSystemChevronDown width={'25px'} height={'25px'} />
                )}
              </Flex>
            </Flex>
          </TertiaryButton>
        )}
      >
        <DropdownMenuItem onSelect={handleAddTextElement}>
          Text
        </DropdownMenuItem>
        <DropdownMenuItem onSelect={handleAddImageElement}>
          Image
        </DropdownMenuItem>
      </DropdownMenu>
    </Flex>
  )
}

const StyledElementSelectionControls = styled.div`
  padding: 16px;
  height: 100%;
`

export const ElementSelectionControls: FC = () => {
  const scene = useSceneProvider()
  const templateProvider = useTemplateProvider()

  const { setElements } = useTemplateElementsCollection()

  const reversedElementsWithIds: StudioElementWithId[] = useMemo(
    () =>
      [
        ...(templateProvider.state.template?.elements?.map((element, index) => {
          const id = index.toString()
          return {
            ...element,
            id,
          }
        }) ?? []),
      ].reverse(),
    [templateProvider.state.template?.elements],
  )

  const onClick = useCallback(() => {
    scene.actions.deselectElement()
  }, [scene])

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const reorderedElements = reorderElements<StudioElementWithId>(
      reversedElementsWithIds,
      result.source.index,
      result.destination.index,
    )

    const reorderedElementsReversed = [...reorderedElements].reverse()

    const elementId = reversedElementsWithIds[result.source.index].id

    const newElementId = reorderedElementsReversed.findIndex(
      element => element.id === elementId.toString(),
    )!

    // After reordering in the UI,
    // set new order in Recoil state
    setElements(reorderedElementsReversed)

    scene.actions.selectElement(newElementId.toString())
  }

  return (
    <StyledElementSelectionControls>
      <ElementSelectionControlsHeader />
      <StyledScrollView
        onClick={onClick}
        data-testid="std-elements-selection-list"
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <ElementSelectionLayerElements elements={reversedElementsWithIds} />
        </DragDropContext>
      </StyledScrollView>
    </StyledElementSelectionControls>
  )
}

type ElementSelectionLayerElementsProps = {
  elements: StudioElementWithId[]
}

export const ElementSelectionLayerElements: FC<
  ElementSelectionLayerElementsProps
> = ({ elements }) => {
  return (
    <Droppable droppableId="droppable">
      {provided => (
        <div {...provided.droppableProps} {...{ ref: provided.innerRef }}>
          {elements.map((element, index) => (
            <Fragment key={`${index}-${element.name}`}>
              <ElementItem draggableIndex={index} element={element} />
            </Fragment>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}
