import { createContext, FC, PropsWithChildren, useContext } from 'react'

type FeatureToggle = {
  enabled: boolean
}

export const defaultFeatureToggles = {
  debug: { enabled: process.env.NODE_ENV !== 'production' },
}

export type FeatureToggleKeys = keyof typeof defaultFeatureToggles
export type FeatureToggles = { [Key in FeatureToggleKeys]: FeatureToggle }

const FeatureTogglesContext = createContext<FeatureToggles>(
  defaultFeatureToggles,
)

type FeatureToggleProviderProps = { featureToggles: FeatureToggles }

export const FeatureTogglesProvider: FC<
  PropsWithChildren<FeatureToggleProviderProps>
> = ({ children, featureToggles }) => (
  <FeatureTogglesContext.Provider value={featureToggles}>
    {children}
  </FeatureTogglesContext.Provider>
)

export const useFeatureToggle = (key: FeatureToggleKeys): boolean => {
  const context = useContext(FeatureTogglesContext)

  if (context === undefined) {
    throw new Error(
      'useFeatureToggle must be used within a FeatureTogglesProvider',
    )
  }

  return context[key]?.enabled
}

export const getQueryStringToggles = (searchString: string): FeatureToggles => {
  const query = new URLSearchParams(searchString)
  const toggleString = query.get('feature')

  if (toggleString) {
    const toggles = toggleString.split(',')

    return toggles.reduce((result, toggle) => {
      const [toggleKey, toggleValue] = toggle.split(':')

      return {
        ...result,
        [toggleKey]: {
          enabled: toggleValue === 'enabled',
        },
      }
    }, defaultFeatureToggles)
  }

  return defaultFeatureToggles
}
