import { GET_INTENTS } from '../graphql/queries'
import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import {
  GetIntentsQuery,
  GetIntentsQueryVariables,
} from '../__graphql__/queries'
import { StudioIntent } from '../__graphql__/types'

export type FetchIntentService = () => Promise<StudioIntent[]>

export const useFetchIntents = (): FetchIntentService => {
  const client = useApolloClient()
  return useCallback(async () => {
    const { data, error } = await client.query<
      GetIntentsQuery,
      GetIntentsQueryVariables
    >({
      errorPolicy: 'all',
      query: GET_INTENTS,
    })

    if (data.studioIntents) {
      return data.studioIntents
    }

    throw new Error(error?.message)
  }, [client])
}
