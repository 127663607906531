import { useCallback } from 'react'
import polly from 'polly-js'
import { MOVE_GROUP } from '../../graphql/mutations'
import { useApolloClient } from '@apollo/client'
import {
  MoveGroupMutation,
  MoveGroupMutationVariables,
} from '../../__graphql__/mutations'
import { StudioMovedGroupResult } from '../../__graphql__/types'

export type MoveGroupService = (
  id: string,
  path: string,
) => Promise<StudioMovedGroupResult>

const shouldNotRetryErrors = ['StudioMoveGroupFailed']

const shouldRetryAfterError = (err: Error) =>
  !shouldNotRetryErrors.includes(err.message)

export const useMoveGroup = (): MoveGroupService => {
  const client = useApolloClient()

  return useCallback(
    async (id, path) => {
      return polly()
        .handle(shouldRetryAfterError)
        .waitAndRetry(5)
        .executeForPromise(async () => {
          const { data } = await client.mutate<
            MoveGroupMutation,
            MoveGroupMutationVariables
          >({
            mutation: MOVE_GROUP,
            variables: {
              input: {
                groupId: id,
                path,
              },
            },
          })

          if (data?.moveGroup.__typename === 'StudioMovedGroup') {
            return data.moveGroup
          }

          throw new Error(data?.moveGroup.code)
        })
    },
    [client],
  )
}
