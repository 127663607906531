import { Size } from '@moonpig/renderer-react'
import { FC, ReactNode, useRef } from 'react'
import { useElementSize } from '../hooks/useElementSize'
import { useSceneProvider } from '../contexts/SceneProvider'

const SceneContainerContent: FC<{
  containerSize: Size
  sceneSize: Size
  renderScene: (size: Size) => ReactNode
}> = ({ containerSize, sceneSize, renderScene }) => {
  const scene = useSceneProvider()
  const containerAspectRatio = containerSize.width / containerSize.height
  const sceneAspectRatio = sceneSize.width / sceneSize.height
  const fillWidth = sceneAspectRatio > containerAspectRatio

  const size: Size = {
    width: fillWidth
      ? containerSize.width
      : containerSize.height * sceneAspectRatio,
    height: fillWidth
      ? containerSize.width / sceneAspectRatio
      : containerSize.height,
  }

  size.width *= scene.state.zoom
  size.height *= scene.state.zoom

  return (
    <div
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: `100%`,
        height: `100%`,
        overflow: 'auto',
        display: 'flex',
      }}
    >
      <div
        onClick={() => {
          scene.dispatch({ type: 'SET_SELECTED_ELEMENT' })
        }}
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: `100%`,
          height: `100%`,
        }}
      ></div>

      <div
        style={{
          width: `${size.width}px`,
          height: `${size.height}px`,
          margin: 'auto',
        }}
      >
        <div>{renderScene(size)}</div>
      </div>
    </div>
  )
}

export const SceneContainer: FC<{
  sceneSize: Size
  renderScene: (size: Size) => ReactNode
}> = ({ sceneSize, renderScene }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const containerSize = useElementSize(containerRef)
  const canRenderContainerContent =
    containerSize?.width! > 0 && containerSize?.height! > 0

  return (
    <div
      style={{
        position: 'absolute',
        top: '45px',
        right: '0',
        bottom: '0',
        left: '0',
        padding: '60px',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '0',
          right: '0',
          bottom: '0',
          left: '0',
        }}
      ></div>
      <div
        ref={containerRef}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {canRenderContainerContent && (
          <div>
            <SceneContainerContent
              containerSize={containerSize!}
              sceneSize={sceneSize}
              renderScene={renderScene}
            />
          </div>
        )}
      </div>
    </div>
  )
}
