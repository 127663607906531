import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { GET_ROOT_GROUPS_QUERY } from '../graphql/queries'
import { StudioGroup } from '../types'
import {
  GetRootGroupsQuery,
  GetRootGroupsQueryVariables,
} from '../__graphql__/queries'

export type FetchRootGroupsService = () => Promise<StudioGroup>

export const useFetchRootGroups = (): FetchRootGroupsService => {
  const client = useApolloClient()

  return useCallback(async () => {
    const { data } = await client.query<
      GetRootGroupsQuery,
      GetRootGroupsQueryVariables
    >({
      errorPolicy: 'all',
      query: GET_ROOT_GROUPS_QUERY,
    })

    if (data.studioRootGroups.__typename === 'StudioGroup') {
      return data.studioRootGroups
    }

    throw new Error(data.studioRootGroups.__typename)
  }, [client])
}
