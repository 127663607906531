import React, { FC } from 'react'
import { InteractiveEditableTextFrame } from '../InteractiveEditableTextFrame'
import { StudioTextElement } from '../../types'
import { isPlaceholderTextPart, isTextElement } from '../../utils'
import { InteractivePlaceholderTextFrame } from '../InteractivePlaceholderTextFrame/InteractivePlaceholderTextFrame'
import { useSceneProvider } from '../../contexts/SceneProvider'

type InteractiveEditableTextProps = {
  id: string
  unitsPerPixel: number
  x: number
  y: number
  width: number
  height: number
  rotation: number
  elementData: StudioTextElement
}

export const InteractiveEditableText: FC<InteractiveEditableTextProps> = ({
  id,
  x,
  y,
  width,
  height,
  rotation,
  unitsPerPixel,
  elementData,
}) => {
  const scene = useSceneProvider()
  const selectedElementId = scene.state.selectedElement?.id
  const isSelected = id === selectedElementId

  const editingText = scene.state.showInlineEditing && isSelected

  const handleClick = () => {
    if (!editingText) {
      scene.actions.selectElement(
        id,
        isPlaceholderTextPart(elementData.text) ? 0 : undefined,
      )
    }
  }

  const commonProps = {
    editingText,
    unitsPerPixel,
    ariaLabel: 'Edit Text',
    selected: isSelected,
    onClick: handleClick,
    elementDataId: id,
    frame: { x, y, width, height, rotation },
  }

  if (!isTextElement(elementData)) {
    return null
  }

  return isPlaceholderTextPart(elementData.text) ? (
    <InteractivePlaceholderTextFrame key={id} {...commonProps} />
  ) : (
    <InteractiveEditableTextFrame key={id} {...commonProps} />
  )
}
